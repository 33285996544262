<template>
  <div class="countdown-wrapper">
    <div v-for="(item, i) in showTimer" :key="i" class="countdown-wrapper-item" :class="[`is-${theme}`]">
      <span class="countdown-wrapper-item__text">{{ item }}</span>
      <span v-if="i !== showTimer.length - 1" class="countdown-wrapper-item__splitter">{{ splitter }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useTimer } from "~~/composables/utils/timer"
import moment from "moment"

const A_SECONDS = 1000
const A_MINUTES = A_SECONDS * 60
const A_HOUR = A_MINUTES * 60
const A_DAY = A_HOUR * 24

type CountdownMode = "relative"
type CountdownTheme = "dark" | "light"
interface ICountdownProps {
  value?: number | Date | string
  isLocale?: boolean
  splitter?: string
  mode?: CountdownMode
  theme?: CountdownTheme
}
const props = withDefaults(defineProps<ICountdownProps>(), {
  value() {
    return null
  },
  isLocale: false,
  splitter: ":",
  mode: "relative",
  theme: "dark"
})

const emits = defineEmits(["timeout"])

const timer = useTimer()
const TIMER_ID = Symbol("")
function setTimer() {
  if (!props.value) {
    return
  }
  timer.set({
    id: TIMER_ID,
    handler: getTime,
    timeout: A_SECONDS
  })
}
const isLoaded = ref(false)

watch(
  () => props.value,
  () => {
    getTime()
    setTimer()
  }
)
const day = ref(0)
const hours = ref(0)
const minutes = ref(0)
const second = ref(0)

const showTimer = computed(() => {
  return [
    `${isLoaded.value ? addZero(day.value) : "?"}d`,
    `${isLoaded.value ? addZero(hours.value) : "?"}h`,
    `${isLoaded.value ? addZero(minutes.value) : "?"}m`,
    `${isLoaded.value ? addZero(second.value) : "?"}s`
  ]
})

function addZero(num: number): string | number {
  if (num < 10) {
    return `0${num}`
  }
  return num
}

function getTimeStamp() {
  const { value } = props
  let time = 0
  if (isNumber(value)) {
    const timestamp = value.toString().length > 10 ? value : value * 1000
    time = new Date(timestamp).getTime()
  } else if (isString(value)) {
    time = new Date(value).getTime()
  } else if (isDate(value)) {
    time = value.getTime()
  }

  return props.isLocale ? moment(time).utc().local().unix() * 1000 : time
}

function getTime() {
  const timeStamp = getTimeStamp()
  if (props.mode === "relative") {
    const duration = getRelativeTime(timeStamp)

    if (duration <= 0) {
      emits("timeout")
      timer.delete(TIMER_ID)
    }
  }
}

function getRelativeTime(timeStamp: number) {
  const now = Date.now()
  const lsNow = props.isLocale ? moment(now).utc().local().unix() * 1000 : moment(now).utc().unix() * 1000
  const duration = timeStamp - lsNow
  if (!isLoaded.value) {
    isLoaded.value = true
  }
  if (duration <= 0) {
    day.value = hours.value = minutes.value = second.value = 0
    return duration
  }
  day.value = Math.floor(duration / A_DAY)
  hours.value = Math.floor((duration % A_DAY) / A_HOUR)
  minutes.value = Math.floor((duration % A_HOUR) / A_MINUTES)
  second.value = Math.floor((duration % A_MINUTES) / A_SECONDS)
  return duration
}

// onMounted(() => {
//   props.value && setTimer()
// })

onUnmounted(() => {
  timer.delete(TIMER_ID)
})

defineExpose({
  setTimer
})
</script>
<style lang="scss" scoped>
.countdown-wrapper {
  display: inline-flex;
  //   justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  @include fontSemibold;
  font-size: 22px;
  line-height: 27px;

  &-item {
    &__text {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 41px;
      border-radius: 4px;
    }

    &__splitter {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 27px;
    }

    &.is-dark {
      color: #ffffff;
      .countdown-wrapper-item__text {
        background: #141b24;
      }
    }

    &.is-light {
      color: #000000;
      .countdown-wrapper-item__text {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
